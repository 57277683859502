import React, { useState } from "react";
import { MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import cloud from "../assets/Cloud.png";
import HouseGif from "../assets/house.gif";
import CarGif from "../assets/car.gif";
import PlaneGif from "../assets/plane.gif";
import PlayButtonGif from "../assets/playButton.gif";
import { isAddress } from "ethereum-address";
import validate from "bitcoin-address-validation";
import { TouchBallLoading } from "react-loadingg";
import Tabs, { TabPane } from "rc-tabs";

const Home = () => {
  const [index, setIndex] = useState(0);
  const [addr, setAddr] = useState("");
  const [tonne, setTonne] = useState([0, 0]); // BTC/ETH Tonne, Poly Tonne
  const [ntx, setNtx] = useState([0, 0]); // BTC/ETH, Poly
  const [exceeded, setExceeded] = useState([false, false]); // BTC/ETH, Poly
  const [allTrans, setAllTrans] = useState([0, 0]); // [BTC/ETH, Poly] Only useable with bitcoin | ETH api does not support data about all the number of transactions
  const [lastValid, setLastValid] = useState("NaN"); // Lastvalid
  const [checked, setChecked] = useState([true, true]); //Ethereum, Polygon

  let calculating = false;

  const isMobile = /Mobi/.test(navigator.userAgent);

  function calculate(address) {
    const addresType = calc_validate(address);

    if (addresType === "e") {
      if (!checked[0] && !checked[1]) {
        alert("Please choose at least one network to calculate!");
        return;
      }

      if (address.length === 40) {
        address = "0x" + address;
      }
    } else if (addresType !== "b") {
      alert("Invalid Hash-ID");
      return;
    }

    if (calculating) return; //If calculating, don't start another

    setTonne([0, 0]);
    setNtx([0, 0]);
    calculating = true;
    setIndex(1);
    setExceeded([false, false]);
    setAllTrans([0, 0]);

    switch (calc_validate(address)) {
      case "b": {
        // BITCOIN
        const table_data = {
          2009: 1.0054,
          2010: 0.3674,
          2011: 0.0695,
          2012: 0.0318,
          2013: 0.0265,
          2014: 0.0414,
          2015: 0.046,
          2016: 0.0507,
          2017: 0.0798,
          2018: 0.368,
          2019: 0.2582,
          2020: 0.3003,
          2021: 0.6254,
          2022: 1.1357,
          2023: 1.1357,
        };

        fetch("https://blockchain.info/rawaddr/" + address + "?limit=5000") // Limit is 5000 by default, but I like it this way more
          .then((response) => response.json())
          .then((data) => {
            let content = data["txs"];
            let content_num = 0;

            try {
              content_num = content.length;
            } catch {
              setNtx([0, 0]);
              setAllTrans([0, 0]);
              setTonne([0, 0]);
              calculating = false;
              setLastValid("b");
              setIndex(2);

              return;
            }

            let year = 0;
            let time_stamp = 0;
            let local_tonne = 0;

            setNtx([content_num, ntx[1]]); // content_num can be 5000 maximum
            setAllTrans([parseInt(data["n_tx"]), 0]);

            if (content_num < data["n_tx"]) {
              // Cant work with usestates immediately
              setExceeded([true, exceeded[1]]);
            }

            for (let i = 0; i < content_num; i++) {
              time_stamp = parseInt(content[i]["time"]);
              year = new Date(time_stamp * 1000).getFullYear(); // We get the data in seconds, Date used miliseconds

              local_tonne += table_data[year];
            }
            
            setTonne([local_tonne, tonne[1]]);

            calculating = false;
            setLastValid("b");
            setIndex(2);
          });

        break;
      }
      case "e": {
        // ETHEREUM, POLYGON
        if (checked[0])
          // ETHEREUM
          eth_calculate(address);
        else poly_calculate(address);

        break;
      }
      default: {
        setLastValid("NaN");
        return;
      }
    }
  }

  function poly_calculate(
    address,
    eth_tonne = 0.0,
    eth_ntx = 0,
    eth_allTrans = 0
  ) {
    // All emission year constants were 0.0473, no need for table
    const emission_constant = 0.0473;

    fetch(
      "https://api.polygonscan.com/api?module=account&action=txlist&address=" +
        address +
        "&startblock=0&endblock=99999999&sort=asc&apikey=UKNPR4GWCZ6W7IX8T5N69M1RSQ51S7DH7E"
    )
      .then((response) => response.json())
      .then((data) => {
        let result = data["result"];
        let result_num = result.length;

        let gas_used = 0;
        let gas_price = 0;

        let txn_fee = 0;

        let poly_amount = 0;
        let local_tonne = 0.0;

        setNtx([ntx[0], result_num]);

        if (result_num >= 10000) {
          // api only support 10k transaction data back and has not number of all transactions data
          if (eth_allTrans >= 10000) {
            setExceeded([true, true]);
            setAllTrans([eth_allTrans, 10000]);
          } else {
            setExceeded([false, true]);
            setAllTrans([eth_allTrans, 10000]);
          }
        } else if (eth_allTrans >= 10000) {
          setExceeded([true, false]);
          setAllTrans([eth_allTrans, 0]);
        }

        for (let i = 0; i < result_num; i++) {
          gas_used = result[i]["gasUsed"];
          gas_price = result[i]["gasPrice"];

          txn_fee = gas_used * gas_price;

          poly_amount = txn_fee / 10 ** 18;

          local_tonne += poly_amount * emission_constant;
        }
        setTonne([eth_tonne, local_tonne]);
        setNtx([eth_ntx, result_num]);

        calculating = false;
        setLastValid("e");
        setIndex(2);
      });
  }

  function eth_calculate(address) {
    const table_data = {
      2017: 23.6757019,
      2018: 24.93079558,
      2019: 13.68378273,
      2020: 1.969379413,
      2021: 7.354761663,
      2022: 57.59425382,
      2023: 57.59425382,
    };

    fetch(
      "https://api.etherscan.io/api?module=account&action=txlist&address=" +
        address +
        "&startblock=0&sort=desc&endblock=99999999&apikey=KH6VSMIJAD54ZM1EVVNUYEKMSVIVPERYIA"
    )
      .then((response) => response.json())
      .then((data) => {
        let result = data["result"];
        let result_num = result.length;
        let all_trans = 0;
        let exceeded_value = false;

        setNtx([result_num, ntx[1]]);

        if (result_num >= 10000) {
          // api only support 10k transaction data back and has not number of all transactions data
          exceeded_value = true;
          all_trans = 10000;
        }

        let gas_used = 0;
        let gas_price = 0;
        let time_stamp = 0;
        let year = "";
        let txn_fee = 0; // Wei
        let eth_amount = 0;
        let local_tonne = 0;
        for (let i = 0; i < result_num; i++) {
          gas_used = result[i]["gasUsed"];
          gas_price = result[i]["gasPrice"];
          time_stamp = result[i]["timeStamp"];

          year = new Date(time_stamp * 1000).getFullYear();

          txn_fee = gas_used * gas_price;
          eth_amount = txn_fee / 10 ** 18;

          local_tonne += eth_amount * table_data[year.toString()];
         
        }

        if (checked[1])
          poly_calculate(address, local_tonne, result_num, all_trans);
        else {
          setTonne([local_tonne, 0.0]);
          setNtx([result_num, 0]);
          calculating = false;
          setLastValid("e");
          setIndex(2);

          if (result_num >= 10000) {
            setExceeded([true, false]);
            setAllTrans([result_num, 0]);
          }
        }
      });
  }

  function calc_validate(address) {
    if (validate(address)) {
      // Bitcoin
      return "b";
    }

    if (isAddress(address)) {
      // Ethereum
      return "e";
    }

    return NaN; // Nothing
  }

  const regExp = (e) => {
    setAddr(e.target.value);
  };

  //id - 0(label-text)/1(css suffix)/3(icon-names)
  const getLabelData = (id) => {
    let data = [
      [
        "Bitcoin Wallet",
        "Ethereum&Polygon Wallet",
        "Invalid Wallet Address",
        "Enter Wallet Address Here",
      ],
      ["bitcoin", "ethereum", "red", "none"],
      ["bitcoin", "ethereum", "minus-square", "paste"],
      [true, true, false, false],
    ];

    switch (calc_validate(addr)) {
      case "b":
        return data[id][0];
      case "e":
        return data[id][1];
      default: {
        if (addr.length > 0) return data[id][2];
        else return data[id][3];
      }
    }
  };

  function refresh() {
    window.location.reload();
  }

  var changeTabEvent = function (key) {};

  var getExtraInfo = function (tonne) {
    return (
      <>
        <div className="describerDivTitle">
          {" "}
          The wallet 's CO2 emissions are similar to:{" "}
        </div>{" "}
        <div className="describeContainer">
          <div className="describerDiv">
            {" "}
            <img src={PlaneGif} alt="imgForTheExtraInfoPlane" />{" "}
            <p>
              {" "}
              <span className="bold"> {parseInt(tonne / 1.7)} </span> flights from
              London to New York{" "}
            </p>{" "}
          </div>{" "}
          <div className="describerDiv">
            {" "}
            <img src={HouseGif} alt="imgForTheExtraInfoHouse" />{" "}
            <p>
              {" "}
              <span className="bold"> {parseInt(tonne / 2.7)} </span> UK households'
              heating in a year{" "}
            </p>{" "}
          </div>{" "}
          <div className="describerDiv">
            {" "}
            <img src={CarGif} alt="imgForTheExtraInfoCar" />{" "}
            <p>
              {" "}
              <span className="bold"> {parseInt(tonne / 0.234)} </span> times a 1000
              km trip with a diesel engine car{" "}
            </p>{" "}
          </div>{" "}
          <div className="describerDiv">
            {" "}
            <img src={PlayButtonGif} alt="imgForTheExtraInfoVideo" />{" "}
            <p>
              {" "}
              <span className="bold"> {parseInt(tonne / 0.000036)} </span> hours of
              video streaming{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
      </>
    );
  };

  return (
    <div className="wrapper">
      <div className="hidden-750">
        {" "}
        <a href="https://impactscope.com">
          <img
            className="impactscope-img"
            src="https://impactscope.com/wp-content/themes/co2/img/about_us_logo.svg"
            alt="impactscope"
          />
        </a>{" "}
      </div>{" "}
      {index === 0 ? (
        <>
          <div className="page-0-container">
            <p className="t-title">
              {" "}
              Calculate the lifetime carbon footprint of your crypto wallet(BTC,
              ETH, MATIC){" "}
            </p>{" "}
            {!isMobile ? (
              <>
                <div className="d-flex w-center">
                  <img src={cloud} className="cloud" alt="could" />
                  <MDBInput
                    label={getLabelData(0)}
                    labelClass={"walletAddr-label-" + getLabelData(1)}
                    group
                    error="wrong"
                    success="right"
                    className={"walletAddr-" + getLabelData(1)}
                    onChange={regExp}
                  >
                    <MDBIcon
                      fab={getLabelData(3)}
                      icon={getLabelData(2)}
                      pull="right"
                      size="2x"
                      className={"icon-input-" + getLabelData(1)}
                    />{" "}
                  </MDBInput>{" "}
                </div>{" "}
                <div className="d-flex w-center-calculate">
                  <MDBBtn
                    className="mb-3 calculate"
                    onClick={() => calculate(addr)}
                  >
                    <div className="text-calculate"> Calculate </div>{" "}
                  </MDBBtn>{" "}
                </div>{" "}
              </>
            ) : (
              <>
                {" "}
                <div className="d-flex w-center">
                  <img src={cloud} className="cloud-mobile" alt="could" />
                  <MDBInput
                    label={getLabelData(0)}
                    labelClass={"walletAddr-label-" + getLabelData(1)}
                    group
                    error="wrong"
                    success="right"
                    className={"walletAddr-" + getLabelData(1)}
                    onChange={regExp}
                  >
                    <MDBIcon
                      fab={getLabelData(3)}
                      icon={getLabelData(2)}
                      pull="right"
                      size="2x"
                      className={"icon-input-" + getLabelData(1)}
                    />{" "}
                  </MDBInput>{" "}
                </div>{" "}
                <div className="d-flex w-center-calculate">
                  <MDBBtn
                    className="mb-3 calculate mobile"
                    onClick={() => calculate(addr)}
                  >
                    <text className="text-calculate"> Calculate </text>{" "}
                  </MDBBtn>{" "}
                </div>{" "}
              </>
            )}{" "}
            <div className="t-title">
              <div className="d-flex w-center">
                <div className="w-500">
                  <p className="text1">
                    {" "}
                    The ImpactScope historical carbon footprint calculator is
                    the most precise carbon footprint calculator for BTC, ETH
                    and MATIC public addresses.{" "}
                  </p>{" "}
                  <p className="text1">
                    {" "}
                    Our methodology is able to distinguish between the carbon
                    emissions of onchain ETH transfers, ERC - 20 token transfers
                    and smart contract creations.{" "}
                  </p>{" "}
                  <p className="text1">
                    {" "}
                    By proceeding, you agree to our{" "}
                    <a href="https://impactscope.com/terms-of-use/">
                      {" "}
                      Terms of Service{" "}
                    </a>{" "}
                    and{" "}
                    <a href="https:/ / impactscope.com / privacy - policy / ">
                      Privacy Policy{" "}
                    </a>{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </>
      ) : index === 1 ? (
        <>
          {" "}
          <TouchBallLoading color="#5cca89" />
        </>
      ) : (
        <div className="d-flex w-center">
          <div className="w-500">
            {" "}
            {lastValid === "b" ? (
              <>
                {" "}
                <p className="title">
                  {" "}
                  The historical carbon footprint of this Bitcoin wallet is:{" "}
                </p>{" "}
                <div style={{ width: "100%" }} className="flex center">
                  <div className="flex">
                    {" "}
                    {
                      console.log("tonneVal", tonne[0]) // Logs the tonne num for debugging and logging purposes
                    }{" "}
                    {tonne[0]
                      .toLocaleString()
                      .slice(0, 7)
                      .split("")
                      .map((num) =>
                        !isMobile ? (
                          num === "." ? (
                            <img
                              key={num}
                              src={`/assets/numbers/dot.png`}
                              className="comma_dot"
                              alt="dot"
                            />
                          ) : num === "," ? (
                            <img
                              key={num}
                              src={`/assets/numbers/comma.png`}
                              className="comma_dot"
                              alt="comma"
                            />
                          ) : (
                            <img
                              src={`/assets/numbers/${num}.png`}
                              className="number"
                              alt={num}
                            />
                          )
                        ) : num === "." ? (
                          <img
                            key={num}
                            src={`/assets/numbers/dot.png`}
                            className="comma_dot"
                            alt="dot"
                          />
                        ) : num === "," ? (
                          <img
                            key={num}
                            src={`/assets/numbers/comma.png`}
                            className="comma_dot"
                            alt="comma"
                          />
                        ) : (
                          <img
                            src={`/assets/numbers/${num}.png`}
                            className="number-mobile"
                            alt={num}
                          />
                        )
                      )}{" "}
                    {!isMobile ? (
                      <img
                        src="/assets/tonnes.png"
                        className="tonnes"
                        alt="tonnes"
                      />
                    ) : (
                      <p>
                        {" "}
                        <br />{" "}
                        <img
                          src="/assets/tonnes.png"
                          className="tonnes-mobile"
                          alt="tonnes"
                        />{" "}
                      </p>
                    )}{" "}
                  </div>{" "}
                </div>{" "}
                {exceeded[0] ? (
                  <>
                    {" "}
                    <p className="trans-num">
                      {" "}
                      Emissions are calculated based on the number of{" "}
                      <b> available </b> transactions (<b>{ntx[0]}</b> ) made
                      from this account.{" "}
                    </p>{" "}
                    <p className="warning-text">
                      {" "}
                      Transaction limit({ntx[0]}) has been reached!{" "}
                    </p>{" "}
                    <p className="warning-text">
                      {" "}
                      For a manual calculation please contact us at info
                      @impactscope.com{" "}
                    </p>{" "}
                  </>
                ) : ntx[0] !== 0 ? (
                  <p className="trans-num">
                    {" "}
                    Emission calculations are based on the number of onchain
                    transactions({ntx[0]}) linked to this address.{" "}
                  </p>
                ) : (
                  <p className="trans-num red-text">
                    {" "}
                    <b>
                      {" "}
                      There are no transactions on the bitcoin blockchain with
                      this wallet address.{" "}
                    </b>{" "}
                  </p>
                )}{" "}
                {getExtraInfo(tonne[0])}{" "}
              </>
            ) : (
              <Tabs
                defaultActiveKey="1"
                tabbarposition="top"
                onChange={changeTabEvent}
                className="tabs"
              >
                <TabPane
                  className="tabs"
                  tab={<div className="tabStyle"> Ethereum </div>}
                  key="1"
                >
                  {" "}
                  <>
                    {" "}
                    <p className="title">
                      {" "}
                      The historical carbon footprint of this Ethereum wallet
                      is:{" "}
                    </p>{" "}
                    <div style={{ width: "100%" }} className="flex center">
                      <div className="flex">
                        {" "}
                        {
                          console.log("tonneVal", tonne[0]) // Logs the tonne num for debugging and logging purposes
                        }{" "}
                        {tonne[0]
                          .toLocaleString()
                          .slice(0, 7)
                          .split("")
                          .map((num) =>
                            !isMobile ? (
                              num === "." ? (
                                <img
                                  key={num}
                                  src={`/assets/numbers/dot.png`}
                                  className="comma_dot"
                                  alt="dot"
                                />
                              ) : num === "," ? (
                                <img
                                  key={num}
                                  src={`/assets/numbers/comma.png`}
                                  className="comma_dot"
                                  alt="comma"
                                />
                              ) : (
                                <img
                                  src={`/assets/numbers/${num}.png`}
                                  className="number"
                                  alt={num}
                                />
                              )
                            ) : num === "." ? (
                              <img
                                key={num}
                                src={`/assets/numbers/dot.png`}
                                className="comma_dot"
                                alt="dot"
                              />
                            ) : num === "," ? (
                              <img
                                key={num}
                                src={`/assets/numbers/comma.png`}
                                className="comma_dot"
                                alt="comma"
                              />
                            ) : (
                              <img
                                src={`/assets/numbers/${num}.png`}
                                className="number-mobile"
                                alt={num}
                              />
                            )
                          )}{" "}
                        {!isMobile ? (
                          <img
                            src="/assets/tonnes.png"
                            className="tonnes"
                            alt="tonnes"
                          />
                        ) : (
                          <p>
                            {" "}
                            <br />{" "}
                            <img
                              src="/assets/tonnes.png"
                              className="tonnes-mobile"
                              alt="tonnes"
                            />{" "}
                          </p>
                        )}{" "}
                      </div>{" "}
                    </div>{" "}
                    {exceeded[0] ? (
                      <>
                        {" "}
                        <p className="trans-num">
                          {" "}
                          Emissions are calculated based on the number of{" "}
                          <b> available </b> transactions (<b>{ntx[0]}</b> )
                          made from this account on the Ethereum Mainnet.{" "}
                        </p>{" "}
                        <p className="warning-text">
                          {" "}
                          Transaction limit({ntx[0]}) has been reached!{" "}
                        </p>{" "}
                        <p className="warning-text">
                          {" "}
                          For a manual calculation please contact us at info
                          @impactscope.com{" "}
                        </p>{" "}
                      </>
                    ) : ntx[0] !== 0 ? (
                      <p className="trans-num">
                        {" "}
                        Emission calculations are based on the number of onchain
                        transactions({ntx[0]}) linked to this address.{" "}
                      </p>
                    ) : (
                      <p className="trans-num red-text">
                        {" "}
                        <b>
                          {" "}
                          There are no transactions on the ethereum blockchain
                          with this wallet address.{" "}
                        </b>{" "}
                      </p>
                    )}{" "}
                    {getExtraInfo(tonne[0])}{" "}
                  </>{" "}
                </TabPane>{" "}
                <TabPane
                  className="tabs"
                  tab={<div className="tabStyle"> Polygon </div>}
                  key="2"
                >
                  {" "}
                  <>
                    {" "}
                    <p className="title">
                      {" "}
                      The historical carbon footprint of this Polygon wallet is:{" "}
                    </p>{" "}
                    <div style={{ width: "100%" }} className="flex center">
                      <div className="flex">
                        {" "}
                        {
                          console.log("tonneVal", tonne[1]) // Logs the tonne num for debugging and logging purposes
                        }{" "}
                        {tonne[1]
                          .toLocaleString()
                          .slice(0, 7)
                          .split("")
                          .map((num) =>
                            !isMobile ? (
                              num === "." ? (
                                <img
                                  key={num}
                                  src={`/assets/numbers/dot.png`}
                                  className="comma_dot"
                                  alt="dot"
                                />
                              ) : num === "," ? (
                                <img
                                  key={num}
                                  src={`/assets/numbers/comma.png`}
                                  className="comma_dot"
                                  alt="comma"
                                />
                              ) : (
                                <img
                                  src={`/assets/numbers/${num}.png`}
                                  className="number"
                                  alt={num}
                                />
                              )
                            ) : num === "." ? (
                              <img
                                key={num}
                                src={`/assets/numbers/dot.png`}
                                className="comma_dot"
                                alt="dot"
                              />
                            ) : num === "," ? (
                              <img
                                key={num}
                                src={`/assets/numbers/comma.png`}
                                className="comma_dot"
                                alt="comma"
                              />
                            ) : (
                              <img
                                src={`/assets/numbers/${num}.png`}
                                className="number-mobile"
                                alt={num}
                              />
                            )
                          )}{" "}
                        {!isMobile ? (
                          <img
                            src="/assets/tonnes.png"
                            className="tonnes"
                            alt="tonnes"
                          />
                        ) : (
                          <p>
                            {" "}
                            <br />{" "}
                            <img
                              src="/assets/tonnes.png"
                              className="tonnes-mobile"
                              alt="tonnes"
                            />{" "}
                          </p>
                        )}{" "}
                      </div>{" "}
                    </div>{" "}
                    {exceeded[1] ? (
                      <>
                        {" "}
                        <p className="trans-num">
                          {" "}
                          Emissions are calculated based on the number of{" "}
                          <b> available </b> transactions (<b>{ntx[1]}</b> )
                          made from this account on the Polygon Mainnet.{" "}
                        </p>{" "}
                        <p className="warning-text">
                          {" "}
                          Transaction limit({ntx[1]}) has been reached!{" "}
                        </p>{" "}
                        <p className="warning-text">
                          {" "}
                          For a manual calculation please contact us at info
                          @impactscope.com{" "}
                        </p>{" "}
                      </>
                    ) : ntx[1] !== 0 ? (
                      <p className="trans-num">
                        {" "}
                        Emission calculations are based on the number of onchain
                        transactions({ntx[1]}) linked to this address.{" "}
                      </p>
                    ) : (
                      <p className="trans-num red-text">
                        {" "}
                        <b>
                          {" "}
                          There are no transactions on the polygon blockchain
                          with this wallet address.{" "}
                        </b>{" "}
                      </p>
                    )}{" "}
                    {getExtraInfo(tonne[1])}{" "}
                  </>{" "}
                </TabPane>{" "}
              </Tabs>
            )}{" "}
            <MDBBtn
              href="https://impactscope.com/offset_bitcoin/"
              className="mb-3 offset"
            >
              <text className="text-offset"> Offset now </text>{" "}
            </MDBBtn>{" "}
            <p className="w-center">
              {" "}
              <MDBBtn onClick={refresh} className="mb-3 new-calculation">
                <text className="new-calculation-text"> New Calculation </text>{" "}
              </MDBBtn>{" "}
            </p>{" "}
            <div className="t-texts">
              <p className="t-text1">
                {" "}
                All of our offsetting project partners are certified by Verra or
                Gold Standard, the most reputable carbon offset certification
                bodies in the world.{" "}
              </p>{" "}
              <p className="t-text2">
                {" "}
                By proceeding, you agree to our{" "}
                <a href="https://impactscope.com/terms-of-use/">
                  {" "}
                  Terms of Service{" "}
                </a>{" "}
                and{" "}
                <a href="https:/ / impactscope.com / privacy - policy / ">
                  Privacy Policy{" "}
                </a>{" "}
              </p>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}{" "}
      <div className="bottom-container">
        {" "}
        <p className="bottom">
          <a href="https://impactscope.com/"> impactscope.com </a>{" "}
        </p>{" "}
      </div>{" "}
    </div>
  );
};
export default Home;
